let PheasantImg = {
  pheasant1: require("./IMG_2151.jpeg"),
  pheasant2: require("./IMG_2153.jpeg"),
  pheasant3: require("./IMG_2154.jpeg"),
  pheasant4: require("./IMG_2192.jpeg"),
  pheasant5: require("./IMG_2434.jpeg"),
  pheasant6: require("./IMG_2441.jpeg"),
};

export default PheasantImg;
